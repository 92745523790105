* {
  font-size: 1.1rem;
}

@media screen and (min-width: 601px) {
  .app-logo {
    height: 10vh;
  }

  .navbar-collapse {
    padding-right: 50px;
  }

  .app-text {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
  }

  .app-home-image {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
  }

  .app-grid {
    padding-bottom: 1%;
    padding-top: 1%;
    padding-left: 1%;
    padding-right: 1%;
  }
}

@media screen and (max-width: 600px) {
  .app-logo {
    width: 90vw;
    height: 13vh;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .app-text {
    padding-bottom: 3%;
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .app-grid {
    padding-bottom: 3%;
    padding-top: 3%;
    padding-left: 3%;
    padding-right: 3%;
  }

  .app-home-image {
    padding-bottom: 2%;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    width: 100%;
  }
}

$body-bg: #f2f8ff;

$theme-colors: (
  "light": #ffffff,
  "primary": #143d59,
);

.app-link {
  color: #173753;
  text-size-adjust: 3rem;
}

.app-link:hover {
  color: black;
}

@import "~bootstrap/scss/bootstrap";
